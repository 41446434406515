import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../../modules/layout';
import PressReleaseDownload from '../../../components/newsroom/download';
import { getCopy } from '../../../helpers/copy';
import filterByCurrentLocale from '../../../helpers/filterByCurrentLocale';
import { useLocale } from '../../../context/locale.context';

const DownloadNewsletterDocuments = () => {
  const { locale } = useLocale();
  const { allContentfulMicrocopy, allPDFs } = useStaticQuery(
    graphql`
      fragment image on ContentfulImage {
        id
        image {
          description
          title
          file {
            url
            contentType
          }
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
        imageCaption {
          raw
        }
        format
        internal {
          type
        }
      }

      fragment document on ContentfulDocument {
        document {
          title
          file {
            contentType
            details {
              size
            }
            url
          }
        }
        language
      }

      query {
        allPDFs: allContentfulPressReleaseDocument(
          filter: { type: { eq: "PDF" } }
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            node_locale
            type
            date
            document {
              ...document
              ...image
            }
          }
        }
        allContentfulMicrocopy {
          nodes {
            node_locale
            key
            value
          }
        }
      }
    `
  );

  const microcopy = allContentfulMicrocopy.nodes
    .filter((node) => node.node_locale === locale)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: curr.value,
      };
    }, {});

  return (
    <Layout
      title={getCopy('title.downloadNewsletterDocuments', microcopy)}
      description={getCopy('desc.downloadNewsletterDocuments', microcopy)}
      slug={'if-magazine/newsroom/download-newsletter-documents'}
    >
      <PressReleaseDownload
        headline={getCopy('label.downloadNewsletterDocuments', microcopy)}
        files={filterByCurrentLocale(allPDFs.nodes, locale)}
      />
    </Layout>
  );
};

export default DownloadNewsletterDocuments;
